import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, withModifiers as _withModifiers, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col justify-center bg-white rounded-lg p-8 border-2 border-gray-300" }
const _hoisted_2 = { class: "space-y-8 divide-y divide-gray-200 sm:space-y-5" }
const _hoisted_3 = { class: "text-lg leading-6 font-medium text-gray-900" }
const _hoisted_4 = { class: "mt-1 max-w-2xl text-sm text-gray-500" }
const _hoisted_5 = { class: "flex flex-col gap-4 sm:gap-0" }
const _hoisted_6 = { class: "pt-5" }
const _hoisted_7 = { class: "flex justify-end" }
const _hoisted_8 = {
  type: "submit",
  class: "ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RadioN = _resolveComponent("RadioN")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("form", {
      class: "space-y-8 divide-y divide-gray-200",
      onSubmit: _cache[19] || (_cache[19] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args)), ["prevent"]))
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", null, [
          _createElementVNode("div", null, [
            _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.$t('PreferencesClinicNoStudies.Title')), 1),
            _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('PreferencesClinicNoStudies.PersonalPatientInfo')), 1)
          ]),
          _createElementVNode("div", _hoisted_5, [
            (_openBlock(), _createBlock(_component_RadioN, {
              text: _ctx.$t('PreferencesClinicNoStudies.adhd_sympt_clin'),
              value: _ctx.clinicPreferences.adhd_sympt_clin,
              key: _ctx.clinicPreferences.adhd_sympt_clin,
              disabledOptions: _ctx.disabledOptions,
              onChanged: _cache[0] || (_cache[0] = ($event: any) => (_ctx.clinicPreferences.adhd_sympt_clin = $event))
            }, null, 8, ["text", "value", "disabledOptions"])),
            (_openBlock(), _createBlock(_component_RadioN, {
              text: _ctx.$t('PreferencesClinicNoStudies.CGI'),
              value: _ctx.clinicPreferences.CGI,
              key: _ctx.clinicPreferences.CGI,
              disabledOptions: _ctx.disabledOptions,
              onChanged: _cache[1] || (_cache[1] = ($event: any) => (_ctx.clinicPreferences.CGI = $event))
            }, null, 8, ["text", "value", "disabledOptions"])),
            (_openBlock(), _createBlock(_component_RadioN, {
              text: _ctx.$t('PreferencesClinicNoStudies.drug_use_prevention'),
              value: _ctx.clinicPreferences.drug_use_prevention,
              key: _ctx.clinicPreferences.drug_use_prevention,
              disabledOptions: _ctx.disabledOptions,
              onChanged: _cache[2] || (_cache[2] = ($event: any) => (_ctx.clinicPreferences.drug_use_prevention = $event))
            }, null, 8, ["text", "value", "disabledOptions"])),
            (_openBlock(), _createBlock(_component_RadioN, {
              text: _ctx.$t('PreferencesClinicNoStudies.drug_use'),
              value: _ctx.clinicPreferences.drug_use,
              key: _ctx.clinicPreferences.drug_use,
              disabledOptions: _ctx.disabledOptions,
              onChanged: _cache[3] || (_cache[3] = ($event: any) => (_ctx.clinicPreferences.drug_use = $event))
            }, null, 8, ["text", "value", "disabledOptions"])),
            (_openBlock(), _createBlock(_component_RadioN, {
              text: _ctx.$t('PreferencesClinicNoStudies.accidents'),
              value: _ctx.clinicPreferences.accidents,
              key: _ctx.clinicPreferences.accidents,
              disabledOptions: _ctx.disabledOptions,
              onChanged: _cache[4] || (_cache[4] = ($event: any) => (_ctx.clinicPreferences.accidents = $event))
            }, null, 8, ["text", "value", "disabledOptions"])),
            (_openBlock(), _createBlock(_component_RadioN, {
              text: _ctx.$t('PreferencesClinicNoStudies.academic_achivement'),
              value: _ctx.clinicPreferences.academic_achivement,
              key: _ctx.clinicPreferences.academic_achivement,
              disabledOptions: _ctx.disabledOptions,
              onChanged: _cache[5] || (_cache[5] = ($event: any) => (_ctx.clinicPreferences.academic_achivement = $event))
            }, null, 8, ["text", "value", "disabledOptions"])),
            (_openBlock(), _createBlock(_component_RadioN, {
              text: _ctx.$t('PreferencesClinicNoStudies.QoL'),
              value: _ctx.clinicPreferences.QoL,
              key: _ctx.clinicPreferences.QoL,
              disabledOptions: _ctx.disabledOptions,
              onChanged: _cache[6] || (_cache[6] = ($event: any) => (_ctx.clinicPreferences.QoL = $event))
            }, null, 8, ["text", "value", "disabledOptions"])),
            (_openBlock(), _createBlock(_component_RadioN, {
              text: _ctx.$t('PreferencesClinicNoStudies.appetite_decreased'),
              value: _ctx.clinicPreferences.appetite_decreased,
              key: _ctx.clinicPreferences.appetite_decreased,
              disabledOptions: _ctx.disabledOptions,
              onChanged: _cache[7] || (_cache[7] = ($event: any) => (_ctx.clinicPreferences.appetite_decreased = $event))
            }, null, 8, ["text", "value", "disabledOptions"])),
            (_openBlock(), _createBlock(_component_RadioN, {
              text: _ctx.$t('PreferencesClinicNoStudies.insomnia'),
              value: _ctx.clinicPreferences.insomnia,
              key: _ctx.clinicPreferences.insomnia,
              disabledOptions: _ctx.disabledOptions,
              onChanged: _cache[8] || (_cache[8] = ($event: any) => (_ctx.clinicPreferences.insomnia = $event))
            }, null, 8, ["text", "value", "disabledOptions"])),
            (_openBlock(), _createBlock(_component_RadioN, {
              text: _ctx.$t('PreferencesClinicNoStudies.somnolence'),
              value: _ctx.clinicPreferences.somnolence,
              key: _ctx.clinicPreferences.somnolence,
              disabledOptions: _ctx.disabledOptions,
              onChanged: _cache[9] || (_cache[9] = ($event: any) => (_ctx.clinicPreferences.somnolence = $event))
            }, null, 8, ["text", "value", "disabledOptions"])),
            (_openBlock(), _createBlock(_component_RadioN, {
              text: _ctx.$t('PreferencesClinicNoStudies.dizziness'),
              value: _ctx.clinicPreferences.dizziness,
              key: _ctx.clinicPreferences.dizziness,
              disabledOptions: _ctx.disabledOptions,
              onChanged: _cache[10] || (_cache[10] = ($event: any) => (_ctx.clinicPreferences.dizziness = $event))
            }, null, 8, ["text", "value", "disabledOptions"])),
            (_openBlock(), _createBlock(_component_RadioN, {
              text: _ctx.$t('PreferencesClinicNoStudies.dry_mouth'),
              value: _ctx.clinicPreferences.dry_mouth,
              key: _ctx.clinicPreferences.dry_mouth,
              disabledOptions: _ctx.disabledOptions,
              onChanged: _cache[11] || (_cache[11] = ($event: any) => (_ctx.clinicPreferences.dry_mouth = $event))
            }, null, 8, ["text", "value", "disabledOptions"])),
            (_openBlock(), _createBlock(_component_RadioN, {
              text: _ctx.$t('PreferencesClinicNoStudies.tic'),
              value: _ctx.clinicPreferences.tic,
              key: _ctx.clinicPreferences.tic,
              disabledOptions: _ctx.disabledOptions,
              onChanged: _cache[12] || (_cache[12] = ($event: any) => (_ctx.clinicPreferences.tic = $event))
            }, null, 8, ["text", "value", "disabledOptions"])),
            (_openBlock(), _createBlock(_component_RadioN, {
              text: _ctx.$t('PreferencesClinicNoStudies.seizures'),
              value: _ctx.clinicPreferences.seizures,
              key: _ctx.clinicPreferences.seizures,
              disabledOptions: _ctx.disabledOptions,
              onChanged: _cache[13] || (_cache[13] = ($event: any) => (_ctx.clinicPreferences.seizures = $event))
            }, null, 8, ["text", "value", "disabledOptions"])),
            (_openBlock(), _createBlock(_component_RadioN, {
              text: _ctx.$t('PreferencesClinicNoStudies.vomiting'),
              value: _ctx.clinicPreferences.vomiting,
              key: _ctx.clinicPreferences.vomiting,
              disabledOptions: _ctx.disabledOptions,
              onChanged: _cache[14] || (_cache[14] = ($event: any) => (_ctx.clinicPreferences.vomiting = $event))
            }, null, 8, ["text", "value", "disabledOptions"])),
            (_openBlock(), _createBlock(_component_RadioN, {
              text: _ctx.$t('PreferencesClinicNoStudies.vasovagal_syncope'),
              value: _ctx.clinicPreferences.vasovagal_syncope,
              key: _ctx.clinicPreferences.vasovagal_syncope,
              disabledOptions: _ctx.disabledOptions,
              onChanged: _cache[15] || (_cache[15] = ($event: any) => (_ctx.clinicPreferences.vasovagal_syncope = $event))
            }, null, 8, ["text", "value", "disabledOptions"])),
            (_openBlock(), _createBlock(_component_RadioN, {
              text: _ctx.$t('PreferencesClinicNoStudies.n_drop_ae'),
              value: _ctx.clinicPreferences.n_drop_ae_,
              key: _ctx.clinicPreferences.n_drop_ae_,
              disabledOptions: _ctx.disabledOptions,
              onChanged: _cache[16] || (_cache[16] = ($event: any) => (_ctx.clinicPreferences.n_drop_ae_ = $event))
            }, null, 8, ["text", "value", "disabledOptions"])),
            (_openBlock(), _createBlock(_component_RadioN, {
              text: _ctx.$t('PreferencesClinicNoStudies.n_drop'),
              value: _ctx.clinicPreferences.n_drop_,
              key: _ctx.clinicPreferences.n_drop_,
              disabledOptions: _ctx.disabledOptions,
              onChanged: _cache[17] || (_cache[17] = ($event: any) => (_ctx.clinicPreferences.n_drop_ = $event))
            }, null, 8, ["text", "value", "disabledOptions"]))
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("button", {
            onClick: _cache[18] || (_cache[18] = ($event: any) => (_ctx.$router.back())),
            type: "button",
            class: "bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          }, _toDisplayString(_ctx.$t('PreferencesClinicNoStudies.Back')), 1),
          _createElementVNode("button", _hoisted_8, _toDisplayString(_ctx.$t('PreferencesClinicNoStudies.Save')), 1)
        ])
      ])
    ], 32)
  ]))
}