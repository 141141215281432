
import { Options, Vue } from 'vue-class-component'
import App from '@/models/App'
import Patient from '@/models/Patient'
import RadioN from '@/components/RadioN.vue'
import { Watch } from 'vue-property-decorator'
import { mapActions, mapGetters } from 'vuex'

@Options({
  components: {
    RadioN
  },
  methods: mapActions(['updateClinicPreferences', 'downloadClinicPreferences']),
  computed: mapGetters(['patient', 'clinicPreferences', 'limits'])
})
export default class PreferencesClinicNoStudies extends Vue {
  patient!: Patient
  clinicPreferences!: Patient['clinicPreferences']
  limits!: App['limits']
  disabledOptions: Array<number> = []

  // Actions
  updateClinicPreferences!: (payload: {
    id: number
    clinicPreferences: Patient['clinicPreferences']
  }) => Promise<void>

  downloadClinicPreferences!: () => Promise<void>

  created (): void {
    this.downloadClinicPreferences()
  }

  async submit (): Promise<void> {
    try {
      await this.updateClinicPreferences({
        id: this.patient.id,
        clinicPreferences: this.clinicPreferences
      })

      this.$router.push({
        name: this.$route.meta.next,
        params: { id: this.patient.id }
      })
    } catch {
      this.$router.push({ name: 'Logout' })
    }
  }

  @Watch('clinicPreferences', { deep: true })
  onPreferencesChanged (newValue: Patient['clinicPreferences']): void {
    const { preferences } = this.limits
    const preferencesLimits = Object.keys(preferences).map((p) => +p)
    const count = {}
    for (const [, value] of Object.entries(newValue)) {
      if (preferencesLimits.includes(value)) {
        count[value] = count[value] + 1 || 1
      }
    }

    this.disabledOptions = []

    for (const key in count) {
      if (count[key] >= preferences[key]) {
        this.disabledOptions.push(+key)
      }
    }
  }
}
